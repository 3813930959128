export const DAY = 60;
export const LANGUAGE = 'LANGUAGE';

export const CARD_OFFLINE_BINS = [
  '441779',
  '464252',
  '493137',
  '534092',
  '475440',
  '400000',
  '527901',
  '526087',
  '528014',
  '404470',
  '478733',
  '223504',
  '223430',
  '223420',
  '223600',
  '246001',
  '246000',
  '235019',
  '238003',
  '256000',
  '556150',
  '55323105',
  '534786',
  '451946',
  '404038',
  '553437',
  '428836',
  '559666',
  '436797',
  '559292',
  '524897',
  '533428',
  '531087',
  '532208',
  '525797',
  '513123',
  '535607',
  '534204',
  '536097',
  '519075',
  '539502',
  '556150',
  '52489753',
  '52489763',
  '555244',
  '489683',
  '424605',
  '556766'
];

export const white_list = ['uid_d988dc0c8f', 'uid_243475ffc2'];

export const CARD_REDUCE_BINS = [
  '471880',
  '428813',
  '441779',
  '464252',
  '493137',
  '534092',
  '475440',
  '400000',
  '527901',
  '526087',
  '528014',
  '404470',
  '478733',
  '223504',
  '223430',
  '256000',
  '223420',
  '223600',
  '246001',
  '246000',
  '235019',
  '238003',
  '556150',
  '55323105',
  '534786',
  '451946',
  '404038',
  '553437',
  '428836',
  '559666',
  '436797',
  '559292',
  '524897',
  '533428',
  '531087',
  '532208',
  '525797',
  '513123',
  '535607',
  '534204',
  '536097',
  '519075',
  '539502',
  '556150',
  '52489753',
  '52489763',
  '555244',
  '491090',
  '441112',
  '447420',
  '483317',
  '428836',
  '489683',
  '424605',
  '556766'
];

export const SUNRATE_PER_LIMITED_USER = ['uid_7b590a3bd3', 'uid_243475ffc2', 'uid_6cd1721aaf'];

export const CARD_REDUCE_ZERO = ['400000'];

export const YS_BINS = ['400000', '464252', '404470', '478733', '493137'];

// 不展示操作明细的卡段
export const CARD_NO_OPERATER = ['471880'];

export const QBIT_BINS = ['471880', '475440'];

export const SMS_BINS = ['493137', '478733', '404470'];

export const Cbtis_BINS = ['428813'];

//
export const TRANSFER_USER = ['uid_6cd1721aaf', 'uid_243475ffc2', 'uid_91941f5e56'];

export const NO_DELETE = [
  'uid_91941f5e56',
  'uid_88b441c40d',
  'uid_19dd0e7158',
  'uid_1c6eb15f26',
  'uid_c5fa412bd5',
  'uid_ede889ca8d',
  'uid_1a1adcaa56',
  'uid_88b441c40d',
  'uid_df215c45e0'
];

export const STATE = [
  {
    code: 'AL',
    value: 'Alabama'
  },
  {
    code: 'AK',
    value: 'Alaska'
  },
  {
    code: 'AR',
    value: 'Arkansas'
  },
  {
    code: 'AZ',
    value: 'Arizona'
  },
  {
    code: 'CA',
    value: 'California'
  },
  {
    code: 'CO',
    value: 'Colorado'
  },
  {
    code: 'CT',
    value: 'Connecticut'
  },
  {
    code: 'DE',
    value: 'Delaware'
  },
  {
    code: 'DC',
    value: 'Washington DC'
  },
  {
    code: 'FL',
    value: 'Florida'
  },
  {
    code: 'GA',
    value: 'Georgia'
  },
  {
    code: 'HI',
    value: 'Hawaii'
  },
  {
    code: 'IA',
    value: 'Iowa'
  },
  {
    code: 'ID',
    value: 'Idaho'
  },
  {
    code: 'IL',
    value: 'Illinois'
  },
  {
    code: 'IN',
    value: 'Indiana'
  },
  {
    code: 'KS',
    value: 'Kansas'
  },
  {
    code: 'KY',
    value: 'Kentucky'
  },
  {
    code: 'LA',
    value: 'Louisiana'
  },
  {
    code: 'ME',
    value: 'Maine'
  },
  {
    code: 'MD',
    value: 'Maryland'
  },
  {
    code: 'MA',
    value: 'Massachusetts'
  },
  {
    code: 'MI',
    value: 'Michigan'
  },
  {
    code: 'MN',
    value: 'Minnesota'
  },
  {
    code: 'MS',
    value: 'Mississippi'
  },
  {
    code: 'MO',
    value: 'Missouri'
  },
  {
    code: 'MT',
    value: 'Montana'
  },
  {
    code: 'NC',
    value: 'North Carolina'
  },
  {
    code: 'ND',
    value: 'North Dakota'
  },
  {
    code: 'NE',
    value: 'Nebraska'
  },
  {
    code: 'NH',
    value: 'New Hampshire'
  },
  {
    code: 'NJ',
    value: 'New Jersey'
  },
  {
    code: 'NM',
    value: 'New Mexico'
  },
  {
    code: 'NY',
    value: 'New York'
  },
  {
    code: 'NV',
    value: 'Nevada'
  },
  {
    code: 'OH',
    value: 'Ohio'
  },
  {
    code: 'OK',
    value: 'Oklahoma'
  },
  {
    code: 'OR',
    value: 'Oregon'
  },
  {
    code: 'PA',
    value: 'Pennsylvania'
  },
  {
    code: 'RI',
    value: 'Rhode Island'
  },
  {
    code: 'SD',
    value: 'South Dakota'
  },
  {
    code: 'SC',
    value: 'South Carolina'
  },
  {
    code: 'TN',
    value: 'Tennessee'
  },
  {
    code: 'TX',
    value: 'Texas'
  },
  {
    code: 'UT',
    value: 'Utah'
  },
  {
    code: 'VA',
    value: 'Virginia'
  },
  {
    code: 'VT',
    value: 'Vermont'
  },
  {
    code: 'WA',
    value: 'Washington'
  },
  {
    code: 'WV',
    value: 'West Virginia'
  },
  {
    code: 'WI',
    value: 'Wisconsin'
  },
  {
    code: 'WY',
    value: 'Wyoming'
  }
];

export const UNITS = [
  {
    name: '美元',
    value: 'USD',
    unit: '$'
  }
];

export const UNITS_MAP: any = {
  USD: '$',
  TWD: '台币',
  USDT: 'USDT',
  USDC: 'USDC',
  $: '美元',
};
